import React, {useEffect} from "react";
import "./App.css";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {history} from "./history";
import Home from "./Pages/Home";
import NotFound from "./Pages/Error404";
import translationsEN from './Components/i18n/en/en.json'
import translationsAR from './Components/i18n/ar/ar.json'
import Cookies from "universal-cookie";
import i18n from "i18next";

const cookies = new Cookies();
const resources = {
    en: {
        translation: translationsEN
    },
    ar: {
        translation: translationsAR
    }
};
let langtList = 'en|ar';
let langArray = ['ar', 'en'];
const AppRouter = () => {

    let lang = "";
    let dir = "";
    if (cookies.get("direction") && cookies.get("direction") === "ltr") {
        lang = "en";
        dir = "ltr";
    } else {
        lang = "ar";
        dir = "rtl";
    }
    useEffect(() => {
        let pathNameLang = window.location.pathname.split('/')[1];
        if (pathNameLang && pathNameLang !== lang && langArray.indexOf(pathNameLang) !== -1) {
            cookies.set('direction', pathNameLang === "en" ? 'ltr' : 'rtl');
            lang = pathNameLang;
            document.body.dir = pathNameLang === "en" ? 'ltr' : 'rtl';
        } else {
            document.body.dir = dir;
        }
        if (lang === "en") {
            import('./GeneralCss/all.css')
        } else {
            import('./GeneralCss/all.rtl.css')
        }
        i18n.init({
            resources,
            lng: lang, // this is the default language. Try to put this
            fallbackLng: 'en', // default language
            keySeparator: false, // we do not use keys in form messages.welcome
            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });


    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route
                    exact path="/"
                    render={() => {
                        return (
                            <Redirect to={"/" + lang}/>
                        )
                    }}
                />
                <Route exact path={`/:lang(${langtList})`} component={Home}/>
                <Route component={NotFound}></Route>
            </Switch>
        </Router>
    );
};
export default AppRouter;
