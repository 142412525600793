import React, {Component} from "react";
import {Progress} from 'react-multi-bar-slider';
import NavBar from "../Components/Navbar";
import {
    BsArrowLeftCircle,
    BsArrowRightCircle,
} from "react-icons/all";
import "moment/locale/ar";
import "moment/locale/en-au";
import {withTranslation} from "react-i18next";
import axios from "axios";
import FormInvestment from "../Components/FormInvestment";
import {AwesomeButton} from "react-awesome-button";
import Cookies from "universal-cookie";
import {Spin} from "antd";
import {history} from "../history";

const cookies = new Cookies();

let dir = cookies.get("direction")
    ? cookies.get("direction")
    : "ltr";


class HomeWeb extends Component {

    state = {
        progress: 25,
        pageNumber: 0,
        otherPagesNumber: -1,
        allData: [],
        isLoading: true,
        showFullForm: true
    };

    handle4Slides = (newProgress, newCounter, isButton = false) => {
        this.setState((prevState) => ({
            ...prevState,
            otherPagesNumber: -1, // Reset Style of second slide into navbar.
            progress: isButton ? prevState.progress + newProgress : newProgress,
            pageNumber: isButton ? prevState.pageNumber + newCounter : newCounter
        }));
    }

    handle2Slides = (numberPage, showFullForm) => {
        this.setState((prevState) => ({
            ...prevState,
            progress: 25, // Reset Progress of first slide into navbar.
            pageNumber: 0, // Reset Style of first slide into navbar.
            otherPagesNumber: numberPage,
            showFullForm
        }));
    }

    async componentDidMount() {
        if (window.innerWidth > 1441) {
            let lang = "";
            if (
                localStorage.getItem("i18nextLng") === "en" ||
                localStorage.getItem("i18nextLng") === "en-US"
            ) {
                this.setState({lang: "en"});
                lang = "en";
            } else {
                this.setState({lang: "ar"});
                lang = "ar";
            }


            await axios.get(process.env.REACT_APP_API_URL, {
                params: {
                    "url": `/api/resource/Investor Home Page/Home-${lang}`
                }
            })
                .then((response) => {
                    let allData = response.data.data;
                    this.setState({
                        allData,
                        isLoading: false,
                    });
                });

            setInterval(() => {
                this.state.progress !== 100 ? document.getElementById('id_for_auto_swap_slide').click() : document.querySelector('a.nav-link').click()
            }, 20000)
            }
    }

    render() {
        let {allData, showFullForm, otherPagesNumber} = this.state
        let {t} = this.props

        let rendered = this.state.isLoading ? (
            <>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="example">
                                <Spin size="large" style={{margin: "auto"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (
            <>
                <NavBar history={history} handle4Slides={this.handle4Slides} pageNumber={this.state.pageNumber}
                        handle2Slides={this.handle2Slides} otherPagesNumber={this.state.otherPagesNumber}/>
                <div className="container-fluid container-style" id="Web">

                    <div className='row carousel-hight'>
                        <div className='col-lg-6 col-md-12'>

                            <div id="carouselExampleIndicators" className="carousel slide"
                                // data-bs-touch="false"
                                 data-interval="false"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item  active">
                                        <h1>{allData.variiance_section_title}</h1>
                                        <h2>{allData.variiance_section_sub_title}</h2>
                                        <hr className="hrStyle"/>
                                        <div
                                            dangerouslySetInnerHTML={{__html: allData.variiance_section_description}}/>
                                        <div className="d-flex flex-row justify-content-start mt-5">
                                            <span data-bs-target="#carouselExampleIndicators"
                                                  data-bs-slide-to="0"><AwesomeButton
                                                className={this.state.otherPagesNumber === 4 ? "buttonOne my-full-translate mx-2" : "buttonOne mx-2"}
                                                type="secondary"
                                                onPress={() => this.handle2Slides(4, true)}>{t("Contact Our Team")}</AwesomeButton></span>
                                            {/*<span data-bs-target="#carouselExampleIndicators"*/}
                                            {/*      data-bs-slide-to="0"><AwesomeButton*/}
                                            {/*    className={this.state.otherPagesNumber === 4 ? "buttonOutline my-half-translate mx-2" :*/}
                                            {/*        this.state.otherPagesNumber === 5 ? 'buttonOutline my-full-translate mx-2' : 'buttonOutline mx-2'}*/}
                                            {/*    type="secondary"*/}
                                            {/*    onPress={() => this.handle2Slides(5, false)}>{t("Request Pitch Deck")}</AwesomeButton></span>*/}
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <h1>{allData.our_story_section_title}</h1>
                                        <div
                                            dangerouslySetInnerHTML={{__html: allData.our_story_section_sub_title}}></div>
                                        <hr className="hrStyle"/>
                                        <div
                                            dangerouslySetInnerHTML={{__html: allData.our_story_section_description}}></div>
                                        <div className="d-flex flex-row justify-content-start mt-5">
                                            <span data-bs-target="#carouselExampleIndicators"
                                                  data-bs-slide-to="0"><AwesomeButton className="buttonOne mx-2"
                                                                                      type="secondary"
                                                                                      onPress={() => this.handle2Slides(4, true)}>{t("Contact Our Team")}</AwesomeButton></span>
                                            {/*<span data-bs-target="#carouselExampleIndicators"*/}
                                            {/*      data-bs-slide-to="0"><AwesomeButton className="buttonOutline mx-2"*/}
                                            {/*                                          type="secondary"*/}
                                            {/*                                          onPress={() => this.handle2Slides(5, false)}>{t("Request Pitch Deck")}</AwesomeButton></span>*/}
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <h1>{allData.insights_section_title}</h1>
                                        <hr className="hrStyle"/>
                                        <div className="mb-5"
                                             dangerouslySetInnerHTML={{__html: allData.insights_section_description}}></div>

                                        <div className="d-flex flex-column" style={{maxWidth: "500px"}}>
                                            <div
                                                className=" d-flex flex-row justify-content-between align-items-center my-2 ">
                                                <div><span className="mx-2"><img src={allData.headquarter_gr_logo} alt="logoV"
                                                                                 width={40} height={40}/> </span>
                                                    <span
                                                        className="mx-2 whiteText"> {allData.headquarter_gr_title} </span>
                                                </div>
                                                <div><span
                                                    className="mx-1 orangeText">{allData.headquarter_gr_value1}</span>
                                                    <span
                                                        className="mx-1 orangeText">{allData.headquarter_gr_value2}</span>
                                                </div>
                                            </div>

                                            <div
                                                className=" d-flex flex-row justify-content-between align-items-center my-2">
                                                <div><span className="mx-2"><img alt="logoV2"
                                                    src={allData.enrollment_growth_rate_logo} width={40} height={40}/> </span>
                                                    <span
                                                        className="mx-2 whiteText"> {allData.enrollment_growth_rate_title} </span>
                                                </div>
                                                <div><span
                                                    className="mx-1 orangeText">{allData.enrollment_growth_rate_value1}</span>
                                                    <span
                                                        className="mx-1 orangeText">{allData.enrollment_growth_rate_value2}</span>
                                                </div>
                                            </div>


                                            <div
                                                className=" d-flex flex-row justify-content-between align-items-center my-2 ">
                                                <div><span className="mx-2"><img alt="logoV3"
                                                    src={allData.subscribers_growth_rate_logo} width={40} height={40}/> </span>
                                                    <span
                                                        className="mx-2 whiteText"> {allData.subscribers_growth_rate_title} </span>
                                                </div>
                                                <div><span
                                                    className="mx-1 orangeText">{allData.subscribers_growth_rate_value1}</span>
                                                    <span
                                                        className="mx-1 orangeText">{allData.subscribers_growth_rate_value2}</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="d-flex flex-row justify-content-start mt-5">
                                            <span data-bs-target="#carouselExampleIndicators"
                                                  data-bs-slide-to="0"><AwesomeButton className="buttonOne mx-2"
                                                                                      type="secondary"
                                                                                      onPress={() => this.handle2Slides(4, true)}>{t("Contact Our Team")}</AwesomeButton></span>
                                            {/*<span data-bs-target="#carouselExampleIndicators"*/}
                                            {/*      data-bs-slide-to="0"><AwesomeButton className="buttonOutline mx-2"*/}
                                            {/*                                          type="secondary"*/}
                                            {/*                                          onPress={() => this.handle2Slides(5, false)}>{t("Request Pitch Deck")}</AwesomeButton></span>*/}
                                        </div>

                                    </div>
                                    <div className="carousel-item">
                                        <h1>{allData.opportunity_section_title}</h1>
                                        <hr className="hrStyle"/>
                                        <div
                                            dangerouslySetInnerHTML={{__html: allData.opportunity_section_description}}></div>
                                        <div className="d-flex flex-row justify-content-start mt-5">
                                            <span data-bs-target="#carouselExampleIndicators"
                                                  data-bs-slide-to="0"><AwesomeButton className="buttonOne mx-2"
                                                                                      type="secondary"
                                                                                      onPress={() => this.handle2Slides(4, true)}>{t("Contact Our Team")}</AwesomeButton></span>
                                            {/*<span data-bs-target="#carouselExampleIndicators"*/}
                                            {/*      data-bs-slide-to="0"><AwesomeButton className="buttonOutline mx-2"*/}
                                            {/*                                          type="secondary"*/}
                                            {/*                                          onPress={() => this.handle2Slides(5, false)}>{t("Request Pitch Deck")}</AwesomeButton></span>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-2 col-md-0 fakeDiv'/>
                        <div className='col-lg-4 col-md-12 paddingForm'>

                            <div className={`appearForm ${otherPagesNumber === 4 && 'swingForm'} `}>
                                <FormInvestment/></div>

                            {/*<div className={showFullForm ? "appearForm " : "disAppearForm " }>*/}
                            {/*    <FormInvestment/></div>*/}
                            {/*:*/}
                            {/*<div className={showFullForm ? "disAppearForm" : "appearForm"}>*/}
                            {/*    /!*<FormPitchDeck/>*!/*/}
                            {/*</div>*/}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {dir === "ltr" ?
                                <div className="carousel-btns">
                                    <BsArrowLeftCircle size={25} color={this.state.progress === 25 ? 'gray' : 'orange'}
                                                       data-bs-target="#carouselExampleIndicators"
                                                       data-bs-slide-to={this.state.pageNumber - 1}
                                                       className="carousel-btns--left"
                                                       aria-current="true"
                                                       aria-label="Slide 0"
                                                       onClick={this.state.progress !== 25 ? () => this.handle4Slides(-25, -1, true) : null}/>
                                    <div className='progress-container'>
                                        <Progress progress={this.state.progress}
                                                  className="progress-container__my-progress"/>
                                    </div>
                                    <span id='id_for_auto_swap_slide'
                                          data-bs-target="#carouselExampleIndicators"
                                          data-bs-slide-to={this.state.pageNumber + 1}
                                          onClick={this.state.progress !== 100 ? () => this.handle4Slides(+25, +1, true) : null}>
                                    <BsArrowRightCircle size={25}
                                                        color={this.state.progress === 100 ? 'gray' : 'orange'}
                                                        className="carousel-btns--right"/>
                                    </span>
                                </div> :
                                <div className="carousel-btns">
                                    <BsArrowRightCircle size={25} color={this.state.progress === 25 ? 'gray' : 'orange'}
                                                        data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to={this.state.pageNumber - 1}
                                                        className="carousel-btns--left"
                                                        aria-current="true"
                                                        aria-label="Slide 0"
                                                        onClick={this.state.progress !== 25 ? () => this.handle4Slides(-25, -1, true) : null}/>
                                    <div className='progress-container'>
                                        <Progress progress={this.state.progress}
                                                  className="progress-container__my-progress"/>
                                    </div>
                                    <span id='id_for_auto_swap_slide'
                                          data-bs-target="#carouselExampleIndicators"
                                          data-bs-slide-to={this.state.pageNumber + 1}
                                          onClick={this.state.progress !== 100 ? () => this.handle4Slides(+25, +1, true) : null}>
                                    <BsArrowLeftCircle size={25}
                                                        color={this.state.progress === 100 ? 'gray' : 'orange'}
                                                        className="carousel-btns--right"/>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </>
        );
        return (rendered)
    }
}

export default withTranslation()(HomeWeb);




