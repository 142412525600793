import React from "react";
import logo from "../Images/Group 110.png";
import {NavHashLink} from "react-router-hash-link";
import icolang from "../Images/lang.svg";
import i18n from "./i18n/i18n";
import Cookies from "universal-cookie";
import {withTranslation} from "react-i18next";

const cookies = new Cookies();



class NavBar extends React.Component {
    state = {
        scrolling:false,
        General: this.props.General,
        country:
            localStorage.getItem("i18nextLng") === "en" ||
            localStorage.getItem("i18nextLng") === "en-US"
                ? "English"
                : localStorage.getItem("i18nextLng") === "he"
                    ? "العربية"
                    : "English",
        lanug:
            localStorage.getItem("i18nextLng") === "us" ||
            localStorage.getItem("i18nextLng") === "en-US"
                ? "us"
                : localStorage.getItem("i18nextLng") === "he"
                    ? "eg"
                    : "us",
        direction: cookies.get("direction") ? cookies.get("direction") : "ltr",
    };
    componentDidMount() {
        window.addEventListener('scroll',this.changeNavbarColor)
    }

    changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    changeNavbarColor = () =>{
        if(window.scrollY >= 80){
            this.setState({scrolling:true})
        }
        else{
            this.setState({scrolling:false})
        }
    };

    render() {
        let {t} = this.props;
        return (
            <>
                <nav className={this.state.scrolling ? "navbar navbar-expand-lg navbar-light  fixed-top changebg scrollBackground" :"navbar navbar-expand-lg navbar-light  fixed-top changebg"}>
                    <div className="container-fluid">
                        <a target='_blank' rel="noreferrer" className="navbar-brand" href="https://variiance.com/">
                            <img alt="img" src={logo} className="logo"/>
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div
                            className="offcanvas offcanvas-end"
                            data-bs-scroll="true"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header">
                                <a target='_blank' rel="noreferrer" className="offcanvas-title" id="offcanvasNavbarLabel" href="https://variiance.com/">
                                    <img alt="img" src={logo} className="logo"/>
                                </a>
                                <button
                                    type="button"
                                    className="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="offcanvas-body">
                                <ul
                                    className="navbar-nav navArabic justify-content-center flex-grow-1 pe-3 my-nav-bar"
                                >
                                    <li className="nav-item">
                                        <NavHashLink
                                            to="/"
                                            className={this.props.pageNumber === 0 && this.props.otherPagesNumber === -1? 'nav-link nav-link-' + this.props.pageNumber: 'nav-link'}
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="0"
                                            onClick={() => this.props.handle4Slides(25, 0)}
                                        >
                                            {t("Variiance")}
                                        </NavHashLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavHashLink
                                            to="/"
                                            className={this.props.pageNumber === 1 && this.props.otherPagesNumber === -1? 'nav-link nav-link-' + this.props.pageNumber: 'nav-link'}
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="1"

                                            onClick={() => this.props.handle4Slides(50, 1)}

                                        >
                                            {t("Our Story")}
                                        </NavHashLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavHashLink
                                            to="/"
                                            className={this.props.pageNumber === 2 && this.props.otherPagesNumber === -1? 'nav-link nav-link-' + this.props.pageNumber: 'nav-link'}
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="2"

                                            onClick={() => this.props.handle4Slides(75, 2)}

                                        >
                                            {t("Insights")}
                                        </NavHashLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavHashLink
                                            to="/"
                                            className={this.props.pageNumber === 3 && this.props.otherPagesNumber === -1? 'nav-link nav-link-' + this.props.pageNumber: 'nav-link'}
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="3"
                                            onClick={() => this.props.handle4Slides(100, 3)}

                                        >
                                            {t("Opportunity")}
                                        </NavHashLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavHashLink
                                            to="/"
                                            className={this.props.otherPagesNumber === 4 && this.props.pageNumber === 0? 'nav-link nav-link-' + this.props.otherPagesNumber: 'nav-link'}
                                            data-bs-target="#carouselExampleIndicators"
                                            data-bs-slide-to="0"
                                            onClick={() => this.props.handle2Slides(4, true)}
                                        >
                                            {t("Contact Us")}
                                        </NavHashLink>
                                    </li>
                                </ul>
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarScrollingDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {cookies.get("direction") === "rtl" ? (
                                                <>
                                                    {" "}
                                                    <span>
                            <img  src={icolang}  alt="arabic"/>
                          </span>
                                                    <span className="mx-2">العربية</span>
                                                </>
                                            ) : (
                                                <>
                                                    {" "}
                                                    <span>
                            <img  src={icolang} alt="english"/>
                            <span className="mx-2">English</span>
                          </span>
                                                </>
                                            )}
                                        </a>
                                        <ul
                                            className="dropdown-menu langDrop"
                                            aria-labelledby="navbarScrollingDropdown"
                                        >
                                            <li style={{borderBottom: "2px solid  #ef8900 "}}>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                        if (cookies.get("direction") === "rtl") {
                                                            this.setState({
                                                                lanug: "en",
                                                            })
                                                            localStorage.setItem('direction', "ltr")
                                                            cookies.set('lanug', 'en')
                                                            cookies.set('direction', 'ltr')
                                                            cookies.set('locale_changed', true)
                                                            cookies.set('locale','en')
                                                            cookies.set('language sso','en')
                                                            if (this.props.history.location.pathname.length === 3){
                                                                window.location.href="/";
                                                            }else {
                                                                window.location.href=this.props.location.pathname.substring(3);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    English
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                        if (cookies.get("direction") === "ltr") {
                                                            this.setState({
                                                                lanug: "eg",
                                                            })
                                                            localStorage.setItem('direction', "rtl")
                                                            cookies.set('lanug', 'eg')
                                                            cookies.set('direction', 'rtl')
                                                            cookies.set('locale_changed', true)
                                                            cookies.set('locale','ar')
                                                            cookies.set('language sso','ar')
                                                            if (this.props.history.location.pathname.length === 3){
                                                                window.location.href="/";
                                                            }else {
                                                                window.location.href=this.props.location.pathname.substring(3);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    العربية
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default withTranslation()(NavBar);
