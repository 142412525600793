import React, {Component} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {Translation, withTranslation} from "react-i18next";
import i18n from "./i18n/i18n";
import Select from "react-select"
import {
    AiOutlineUser,
    BsTelephone, CgDropOpacity,
    CgToolbox,
    FaMoneyCheckAlt,
    GiTakeMyMoney, GiThink,
    MdOutlineEmail
} from "react-icons/all";
import moment from "moment";
import 'intl-tel-input/build/js/utils.js'
import "moment/locale/ar";
import "moment/locale/en-au";
import intlTelInput from 'intl-tel-input';
import {AwesomeButton} from "react-awesome-button";
import formSubmit from "../Images/Submit button.json"
import Lottie from "react-lottie-player";
import cities from "./cities.json"
const cookies = new Cookies();


const customStyles = {
    option: (provided, state) => ({
        ...provided, backgroundColor: state.isSelected ? "rgba(220,116,15,0.89)" : 'unset', zIndex: 9999, "&:hover": {
            color: "white", backgroundColor: "rgba(220,116,15,0.89)"
        }
    }), menu: (provided, state) => ({
        ...provided, zIndex: 9999, backgroundColor: "#363636"
    }),
}

class FormInvestment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iti: {},
            isLoading: true,
            errors: {},
            departments: [],
            department: "",
            name: "",
            phone: "",
            email: '',
            jobTitle: "",
            chooseDateTime: "",
            selectedOption: "",
            selectedOptionLooking: "",
            message: '',
            disableButton: false,
            formHide: false,
            RequestType: "",
            cities: [],
            lang: "",
            city:""
        };
        this.onValueChange = this.onValueChange.bind(this);
        this.onValueChangeLooking = this.onValueChangeLooking.bind(this);
    }


    async componentDidMount() {
        if (cookies.get("direction") === "ltr") {
            this.setState({lang: "en"})
        } else {
            this.setState({lang: "ar"})
        }
        const input = document.querySelector("#phone");
        let iti = intlTelInput(input, {
            autoPlaceholder: "on",
            nationalMode: true,
            initialCountry: "eg",
            excludeCountries: ["il"],
            separateDialCode: true,
            preferredCountries: ["eg"],
            utilsScript: "intl-tel-input/build/js/utils.js",

        });
        this.setState({input: input, iti: iti})
        this.setState({cities: cities.cities})

    }

    handleChangeGeneralInputs = (event, name) => {
        this.setState({[event.target.name]: event.target.value.replace(/\s\s/g, '').replace(/\.+/g, '.')}, () => {
            let errors = {};
            let formIsValid = true;
            if (name === "name") {
                if (!this.state.name) {
                    formIsValid = false;
                    errors.name = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.name.length < 3) {
                    formIsValid = false;
                    errors.name = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.name !== "undefined") {
                    if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid = false;
                        errors.name = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.name = ""
                    formIsValid = true;

                }
            }
            if (name === "jobTitle") {
                if (!this.state.jobTitle) {
                    formIsValid = false;
                    errors.jobTitle = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.jobTitle.length < 3) {
                    formIsValid = false;
                    errors.jobTitle = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.jobTitle !== "undefined") {
                    if (!this.state.jobTitle.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid = false;
                        errors.jobTitle = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.jobTitle = ""
                    formIsValid = true;
                }

            }

            if (name === "email") {
                if (!this.state.email) {
                    formIsValid = false;

                    errors.email = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.email !== "undefined") {
                    if (
                        !this.state.email.match(
                            /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                        )
                    ) {
                        formIsValid = false;
                        errors.email = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors.email = ("")
                    formIsValid = true
                }
            }


            this.setState({errors: errors});
            return formIsValid;
        });

    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value.replace(/[^0-9]+/ig, '')}, () => {
            if (!this.state.phone) {
                this.setState({
                    phone_error: <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('field is required')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                })
            } else {
                this.setState({
                    phone_error: ''

                })
            }
            let input = this.state.input
            let iti = this.state.iti
            let errorMsg = document.querySelector("#error-msg"),
                validMsg = document.querySelector("#valid-msg")
            let errorMap = [this.props.t('Invalid number'), this.props.t('Invalid country code'), this.props.t('Too short'), this.props.t('Too long'), this.props.t('Invalid number')];

            let reset = function () {
                input.classList.remove("error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("hide");
                validMsg.classList.add("hide");
            };

            reset();

            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    validMsg.classList.remove("hide");
                    this.setState({disabled: false})
                } else {
                    this.setState({disabled: true})
                    input.classList.add("error");
                    let errorCode = iti.getValidationError();
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("hide");
                }
            }

        });

    };

    handleDate = (value, name) => {
        let errors = {};
        this.setState({[name]: moment(value[0]).format('YYYY/MM/DD')}, () => {

        });
        if (!this.state.chooseDateTime) {
            errors.chooseDateTime = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Date must be specified")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        } else {
            errors.chooseDateTime = (
                ''
            );
        }
        this.setState({errors: errors})
    };

    onValueChange(event) {
        this.setState({
            selectedOption: event.target.value,
        });
    }

    onValueChangeLooking(event) {
        this.setState({
            selectedOptionLooking: event.target.value,
        });
    }

    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!this.state.selectedOptionLooking) {
            formIsValid = false;
            errors.selectedOptionLooking = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Please select from options")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.selectedOption) {
            formIsValid = false;
            errors.selectedOption = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Please select from options")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.RequestType) {
            formIsValid = false;
            errors.RequestType = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Request type must be specified")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.city) {
            formIsValid = false;
            errors.city = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("City must be specified")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (!this.state.name) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (this.state.name.length < 3) {
            formIsValid = false;
            errors.name = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.name !== "undefined") {
            if (!this.state.name.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid = false;
                errors.name = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }
        if (!this.state.jobTitle) {
            formIsValid = false;
            errors.jobTitle = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (this.state.jobTitle.length < 3) {
            formIsValid = false;
            errors.jobTitle = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.jobTitle !== "undefined") {
            if (!this.state.jobTitle.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid = false;
                errors.jobTitle = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        if (!this.state.email) {
            formIsValid = false;

            errors.email = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        } else if (typeof this.state.email !== "undefined") {
            if (
                !this.state.email.match(
                    /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                )
            ) {
                formIsValid = false;
                errors.email = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }
        if (!this.state.phone) {
            formIsValid = false
            this.setState({
                phone_error: <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t('field is required')}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            })
        }

        this.setState({errors: errors})
        return formIsValid;

    }

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disableButton: true})
            const newparams = {
                url: "api/resource/Contact Us Form",
                data: {
                    full_name: this.state.name,
                    country_code: this.state.iti.activeItem.dataset.countryCode,
                    phone_number: !this.state.phone.includes('+') ? '+' + this.state.iti.activeItem.dataset.dialCode + this.state.phone : this.state.phone,
                    email: this.state.email,
                    job_title: this.state.jobTitle,
                    city:this.state.city,
                    what_are_you_looking_for:this.state.RequestType,
                    created_at:moment().format('YYYY/MM/DD'),
                    did_you_invest_before: this.state.selectedOption,
                    are_you_looking_for_long_term_investment: this.state.selectedOptionLooking
                },
            };
            axios
                .post(process.env.REACT_APP_API_URL, newparams)
                .then((response) => {
                    let res = response.status;
                    if (res === 200) {
                        this.setState({
                            name: "",
                            email: "",
                            phone: "",
                            chooseDateTime: "",
                            jobTitle: "",
                            RequestType: "",
                            city: "",
                            selectedOption: "",
                            selectedOptionLooking: "",
                            disableButton: false,
                        });

                        this.setState({formHide: true})
                        setTimeout(() => {
                            this.setState({formHide: false}, () => {
                                const input = document.querySelector("#phone");
                                let iti = intlTelInput(input, {
                                    autoPlaceholder: "on",
                                    nationalMode: true,
                                    initialCountry: "eg",
                                    separateDialCode: true,
                                    excludeCountries: ["il"],
                                    preferredCountries: ["eg"],
                                    utilsScript: "intl-tel-input/build/js/utils.js",

                                });
                                this.setState({input: input, iti: iti})

                            })
                        }, 6000);

                    } else {

                    }
                });
        }

    }
    handleSelect = (e, name) => {
        this.setState({[name]: e.value}, () => {
            let errors = {};
            let formIsValid = true;
            if(name === "RequestType"){
                if (!this.state.RequestType) {
                    formIsValid = false;
                    errors.RequestType = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else {
                    errors.RequestType = ""
                    formIsValid = true;

                }
            }
            if(name === "city"){
                if (!this.state.city) {
                    formIsValid = false;
                    errors.city = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else {
                    errors.city = ""
                    formIsValid = true;

                }
            }


            this.setState({errors: errors});
            return formIsValid;

        });


    };

    render() {
        let {t} = this.props
        const RequestType = [{
            value: "Investment Relations", label: t("Investment Relations")
        }, {
            value: "Partnership", label:t("Partnership")
        }, {
            value: "Pitch deck", label:t("Pitch deck")
        }]


        const cities = this.state.cities.map(res => {
            if (this.state.lang === "en") {
                return {value: res.city_name_en, label: res.city_name_en}
            } else {
                return {value: res.city_name_ar, label: res.city_name_ar}

            }
        })


        return (
            <>
                {this.state.formHide === false ?
                    <form onSubmit={(e) => e.preventDefault()} className="row g-3 px-xl-5 px-md-2">
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><AiOutlineUser
                                        size={20} className="colorIconFrom"/></span>
                            <input type="text" className="form-control  formContactUs"
                                   placeholder={t('username')}
                                   aria-label="name"
                                   maxLength="45"
                                   id="exampleFormControlInput1"
                                   name="name"
                                   value={this.state.name}
                                   onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'name')}
                                   onChange={(e) => this.handleChangeGeneralInputs(e, 'name')}
                            />
                        </div>
                        <span className="err">{this.state.errors.name}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><BsTelephone
                                        size={20} className="colorIconFrom"/></span>
                            <input
                                name="phone"
                                id="phone"
                                type="tel"
                                placeholder={t('Phone Number')}
                                className="form-control formContactUs"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                autoComplete="tel"
                            />

                        </div>
                        <span id="valid-msg"
                              className="hide valid"> ✓ {t("Valid")}</span>
                        <span id="error-msg" className="hide err"></span>
                        <span className="err">{this.state.phone_error}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><MdOutlineEmail
                                        size={20} className="colorIconFrom"/></span>
                            <input type="email" className="form-control formContactUs" placeholder={t('email')}
                                   aria-label="email"
                                   value={this.state.email}
                                   name="email"
                                   onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                   onChange={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                   aria-describedby="addon-wrapping"/>
                        </div>
                        <span className="err">{this.state.errors.email}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><CgToolbox size={20}
                                                                                                      className="colorIconFrom"/></span>
                            <input type="jobTitle" className="form-control formContactUs"
                                   placeholder={t("jobTitle")}
                                   aria-label="jobTitle"
                                   value={this.state.jobTitle}
                                   name="jobTitle"
                                   onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'jobTitle')}
                                   onChange={(e) => this.handleChangeGeneralInputs(e, 'jobTitle')}
                                   aria-describedby="addon-wrapping"/>
                        </div>
                        <span className="err">{this.state.errors.jobTitle}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><CgDropOpacity size={20}
                                                                                                          className="colorIconFrom"/></span>
                            <Select
                                className="form-control formContactUs"
                                classNamePrefix="select"
                                placeholder={t('choose your city ?')}
                                value={cities.find(
                                    (obj) => obj.value === this.state.city
                                )}
                                styles={customStyles}
                                name="city"
                                options={cities}
                                // onChange={this.handleChangeGeneralInputs}
                                onChange={(e) => this.handleSelect(e, 'city')}
                            />
                        </div>
                        <span className="err">{this.state.errors.city}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><GiThink size={20}
                                                                                                    className="colorIconFrom"/></span>
                            <Select
                                className="form-control formContactUs"
                                classNamePrefix="select"
                                placeholder={t('What are you looking for?')}
                                value={RequestType.find(
                                    (obj) => obj.value === this.state.RequestType
                                )}
                                styles={customStyles}
                                name="RequestType"
                                options={RequestType}
                                // onChange={this.handleChangeGeneralInputs}
                                onChange={(e) => this.handleSelect(e, 'RequestType')}
                            />
                        </div>
                        <span className="err">{this.state.errors.RequestType}</span>
                        {/*<div className="input-group flex-nowrap">*/}
                        {/*            <span className="input-group-text" id="addon-wrapping"><GoCalendar size={20}*/}
                        {/*                                                                               className="colorIconFrom"/></span>*/}
                        {/*    <Flatpickr*/}
                        {/*        className="form-control formContactUs"*/}
                        {/*        name="date"*/}
                        {/*        placeholder={t("Date Of Birth")}*/}
                        {/*        wrap={true}*/}
                        {/*        value={this.state.chooseDateTime}*/}
                        {/*        onChange={date => this.handleDate(date, 'chooseDateTime')}*/}
                        {/*        options={{*/}
                        {/*            maxDate: moment().subtract(21, 'years').format('YYYY/MM/DD'),*/}
                        {/*            minDate: moment().subtract(100, 'years').format('YYYY/MM/DD'),*/}
                        {/*            enableTime: false,*/}
                        {/*            dateFormat: "Y/m/d",*/}
                        {/*            disableMobile: "true"*/}
                        {/*        }}*/}

                        {/*    />*/}
                        {/*</div>*/}
                        {/*<span className="err">{this.state.errors.chooseDateTime}</span>*/}
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><GiTakeMyMoney
                                        size={20} className="colorIconFrom"/></span>
                            <div className="form-control formContactUs fontText">
                                <span>{t("Did You Invest Before")}</span>
                                <div className="mt-3">
                                    <div className="form-check form-check-inline">
                                        <label className="inputRadio ">
                                            <input type="radio" name="radio" value="Yes"
                                                   checked={this.state.selectedOption === "Yes"}
                                                   onChange={this.onValueChange}/>

                                            <span className="checkmark"></span>
                                            {t("Yes")}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="inputRadio ">
                                            <input type="radio" name="radio" value="No"
                                                   checked={this.state.selectedOption === "No"}
                                                   onChange={this.onValueChange}/>
                                            <span className="checkmark"></span>
                                            {t("No")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="err">{this.state.errors.selectedOption}</span>
                        <div className="input-group flex-nowrap">
                                    <span className="input-group-text" id="addon-wrapping"><FaMoneyCheckAlt
                                        size={20} className="colorIconFrom"/></span>
                            <div className="form-control formContactUs fontText">
                                <span>{t("Are You Looking For Long Term Investment")}</span>
                                <div className="mt-3">
                                    <div className="form-check form-check-inline">
                                        <label className="inputRadio ">
                                            <input type="radio" name="Looking" value="Yes"
                                                   checked={this.state.selectedOptionLooking === "Yes"}
                                                   onChange={this.onValueChangeLooking}/>
                                            <span className="checkmark"></span>
                                            {t("Yes")}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="inputRadio ">
                                            <input type="radio" name="Looking" value="No"
                                                   checked={this.state.selectedOptionLooking === "No"}
                                                   onChange={this.onValueChangeLooking}/>
                                            <span className="checkmark"></span>
                                            {t("No")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="err">{this.state.errors.selectedOptionLooking}</span>

                        <div className="text-center mt-2 mb-5">
                            <AwesomeButton type="secondary" disabled={this.state.disabled || this.state.disableButton}
                                           onPress={this.handleSubmit}>{t("Submit")}</AwesomeButton>
                        </div>
                    </form>
                    :
                    <div className="d-flex flex-column justify-content-center align-items-center"
                         style={{height: "100%"}}>
                        <Lottie
                            loop={false}
                            animationData={formSubmit}
                            play
                            style={{width: 200}}
                        />
                        <p className="whiteTextCenter">{t("Thank you we will contact you soon")}.</p>
                    </div>
                }
            </>
        );
    }
}

export default withTranslation()(FormInvestment);
