import React, {Component} from "react";
import HomeWeb from "../Components/HomeWeb";
import HomeMob from "../Components/HomeMob";
import video from "../Images/The Earth's Mass 20s.m4v";
import {Helmet} from "react-helmet";
import MetaTags from "react-meta-tags";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Home extends Component {
    state = { width: 0,lang:"",url:""};
    updateDimensions = () => {
        this.setState({ width: window.innerWidth});
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions()
        this.setState({url: this.props.match.url})
        if (cookies.get("direction") && cookies.get("direction") === "ltr") {
            this.setState({lang:"en"})
        } else {
            this.setState({lang:"ar"})
        }
    }
    render() {
        return (
            <div className="AppHome">
                <Helmet>
                    <link href={"https://variiance.org" + this.props.match.url} rel="canonical"/>
                </Helmet>
                {/*<Lottie*/}
                {/*    id="myVideo"*/}
                {/*    loop={true}*/}
                {/*    animationData={LottieV}*/}
                {/*    play*/}
                {/*    style={{margin:"auto"}}*/}
                {/*/>*/}
                <div id="videoIOS">
                    <video autoPlay playsinline muted loop id="myVideo">
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>

                {this.state.width >= 1441?
                <HomeWeb/>:
                <HomeMob/>}
                {this.state.lang === "en" ?
                    <MetaTags>
                    <title>Invest in Variiance for ed-tech</title>
                    <meta content="Variiance is an ed-tech startup, looking for partners and investors.Variiance creates digital learning solutions that combine the latest technology to bring affordable modern online and offline smart learning to the world." name="description"/>
                    <meta content="website" property="og:type"/>
                    <meta content="Invest in Variiance for ed-tech" property="og:title"/>
                    <meta content="https://vcloud.variiance.com/link/hndxwLi8zXP0O20uCjv3evhDtVLAE9R5xUpCeudH.png" property="og:image"/>
                    <meta content="variiance_investment_logo" property="og:image:alt"/>
                    <meta content={"https://variiance.org" + this.state.url} property="og:url"/>
                    <meta content="Variiance Investment" property="og:site_name"/>
                    <meta content="Variiance is an ed-tech startup, looking for partners and investors.Variiance creates digital learning solutions that combine the latest technology to bring affordable modern online and offline smart learning to the world." property="og:description"/>
                    <meta content="Invest in Variiance for ed-tech" name="twitter:title"/>
                    <meta content="https://vcloud.variiance.com/link/hndxwLi8zXP0O20uCjv3evhDtVLAE9R5xUpCeudH.png" name="twitter:image"/>
                    <meta content={"https://variiance.org" + this.state.url} name="twitter:url"/>
                    <meta content="summary_large_image" name="twitter:card"/>
                    <meta content="@Variianceglobal" name="twitter:site"/>
                    </MetaTags>
                    :
                    <MetaTags>
                        <title>استثمر في تكنولوجيا التعليم</title>
                        <meta content="فاريانس هي شركة ناشئة في مجال تكنولوجيا التعليم ، تبحث عن شركاء ومستثمرين
تبتكرفاريانس حلولًا تعليمية ذكية تجمع بين أحدث التقنيات لتقديم تعليم ذكي عن بعد." name="description"/>
                        <meta content="website" property="og:type"/>
                        <meta content="استثمر في تكنولوجيا التعليم" property="og:title"/>
                        <meta content="https://vcloud.variiance.com/link/hndxwLi8zXP0O20uCjv3evhDtVLAE9R5xUpCeudH.png" property="og:image"/>
                        <meta content="شعار_مستثمرين_فاريانس" property="og:image:alt"/>
                        <meta content={"https://variiance.org" + this.state.url} property="og:url"/>
                        <meta content="Variiance Investment" property="og:site_name"/>
                        <meta content="فاريانس هي شركة ناشئة في مجال تكنولوجيا التعليم ، تبحث عن شركاء ومستثمرين
تبتكرفاريانس حلولًا تعليمية ذكية تجمع بين أحدث التقنيات لتقديم تعليم ذكي عن بعد." property="og:description"/>
                        <meta content="استثمر في تكنولوجيا التعليم" name="twitter:title"/>
                        <meta content="https://vcloud.variiance.com/link/hndxwLi8zXP0O20uCjv3evhDtVLAE9R5xUpCeudH.png" name="twitter:image"/>
                        <meta content={"https://variiance.org" + this.state.url} name="twitter:url"/>
                        <meta content="summary_large_image" name="twitter:card"/>
                        <meta content="@Variianceglobal" name="twitter:site"/>
                    </MetaTags>
                }
            </div>
        )
    }
}

export default Home;




