import "./App.css";
import "antd/dist/antd.css";
import 'aos/dist/aos.css';
import React from "react";
import AppRouter from "./Router";
import "react-awesome-button/dist/styles.css";
function App() {


    return (
            <div className="App">
                <AppRouter/>
            </div>
    );
}

export default App;


