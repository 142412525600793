import React from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AwesomeButton} from "react-awesome-button";
import Lottie from "react-lottie-player";
import Error404 from "../Images/404 error.json";

class NotFound extends React.Component {
    state = {
        progress: 25,
        pageNumber: 0,
        otherPagesNumber: -1,
        allData: [],
        isLoading: true,
        showFullForm: true
    };

    handle4Slides = (newProgress, newCounter, isButton = false) => {
        this.setState((prevState) => ({
            ...prevState,
            otherPagesNumber: -1, // Reset Style of second slide into navbar.
            progress: isButton ? prevState.progress + newProgress : newProgress,
            pageNumber: isButton ? prevState.pageNumber + newCounter : newCounter
        }));
    }

    handle2Slides = (numberPage, showFullForm) => {
        this.setState((prevState) => ({
            ...prevState,
            progress: 25, // Reset Progress of first slide into navbar.
            pageNumber: 0, // Reset Style of first slide into navbar.
            otherPagesNumber: numberPage,
            showFullForm
        }));
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <div className="container-fluid pageBackGround">
                    <div className="row Page404">
                        <div className="col-lg-6 m-auto">
                            <div className="text-center">
                                <div className="div404">
                                    <p className="NotfoundTxt"> {t("What Are You Doing Here Bro..?")}</p>
                                    <p className="NotfoundTxtP my-4"> {t("404 Not Found")}</p>
                                    <Lottie
                                        loop={true}
                                        animationData={Error404}
                                        play
                                        className="Img404 my-5"
                                        style={{ width: 700,margin:"auto" }}
                                    />
                                    <div className="mt-5">
                                        <Link to="/">
                                            <AwesomeButton type="secondary"
                                                           className="backHome"
                                            >{t("Back Home")}</AwesomeButton>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(NotFound);
